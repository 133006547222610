import React, { useState } from "react";
import moment from "moment";
import useSort from "../Sorting";
import { HiDocumentCheck, HiCurrencyDollar } from "react-icons/hi2";
import { TiStarFullOutline } from "react-icons/ti";

const orderDetailsApi = process.env.REACT_APP_ORDER_DETAILS_API_ENDPOINT;

const Table = ({
  className = "",
  currentPage,
  pageSize,
  appliedFilters,
  rowsData,
  columns,
  loading,
  error,
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const handleSort = (columnId) => {
    const direction =
      sortConfig.key === columnId && sortConfig.direction === "asc"
        ? "desc"
        : "asc";
    setSortConfig({ key: columnId, direction });
  };

  const sortedData = useSort(rowsData, sortConfig).slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const columnColors = (column, value) => {
    if (value === "Declined" || value === "Canceled") {
      return "bg-[#ffecf0] text-[#c82124]";
    } else if (value === "Approved") {
      return "bg-[#e0f5f0] text-[#007958]";
    } else if (value === "On Hold") {
      return "bg-[#ffe8cd] text-[#ec8100]";
    }

    if (column.id === "ship_date") {
      const expectedDate = moment(value, "M/D/YY");
      const currentDate = moment();
      if (expectedDate.isSame(currentDate, "day")) {
        return "bg-[#ffe8cd] text-[#ec8100]";
      }
      if (expectedDate.isBefore(currentDate, "day")) {
        return "bg-[#ffecf0] text-[#c82124]";
      }
    }
  };


  let linkClicked = false;
  let pcqueueTab = null;

  function openLinkInTab(event, url) {
    event.preventDefault();
    if (!linkClicked || pcqueueTab === null || pcqueueTab.closed) {
      pcqueueTab = window.open(url, "_queue");
    } else {
      pcqueueTab.location.href = url;
    }
    linkClicked = true;
  }

  const COLUMN_LABELS = {
    PRODUCT_TYPE: "Product Type",
    LOCATION: "Location",
    ORDER_NUMBER: "Order #",
    JOB_NUMBER: "Job #",
    APPROVED: "Approved",
    PRICE_FLAG: "Price Flag",
  };

  const renderCellValue = (
    column,
    row,
    orderDetailsApi,
    openLinkInTab,
    appliedFilters
  ) => {
    const value = column.filterOptions
      ? column?.filterOptions?.find(
          (item) => item.value === row.attributes[column.id]
        )?.label
      : row.attributes[column.id];

    switch (column.label) {
      case COLUMN_LABELS.PRODUCT_TYPE:
      case COLUMN_LABELS.LOCATION:
        return <span title={value}>{value}</span>;

      case COLUMN_LABELS.ORDER_NUMBER:
        return (
          <div className="flex flex-row justify-end items-center">
            {row.attributes.star === "1" && (
              <TiStarFullOutline className="text-yellow-500 mt-1 w-6 text-right" />
            )}
            <a
              className="text-right text-blue-800 ml-auto"
              href={orderDetailsApi + row.attributes.order_id}
              onClick={(event) =>
                openLinkInTab(event, orderDetailsApi + row.attributes.order_id)
              }
            >
              {value}
            </a>
          </div>
        );

      case COLUMN_LABELS.JOB_NUMBER:
        return (
          <a
            href={orderDetailsApi + row.attributes.order_id + "&JOB=" + value}
            onClick={(event) =>
              openLinkInTab(
                event,
                orderDetailsApi + row.attributes.order_id + "&JOB=" + value
              )
            }
          >
            {value}
          </a>
        );

      case COLUMN_LABELS.APPROVED:
        if (value === "1") {
          return (
            <HiDocumentCheck className="w-6 h-6 bg-white-500 border-white text-green-600 inline" />
          );
        }
        break;

      case COLUMN_LABELS.PRICE_FLAG:
        if (value === "true") {
          return (
            <HiCurrencyDollar className="w-6 h-6 bg-white-500 border-white text-[#ff9b00] inline" />
          );
        }
        break;

      default:
        return column.component ? (
          <column.component data={row} appliedFilters={appliedFilters} />
        ) : column.format && value ? (
          column.format(value)
        ) : (
          value || "-"
        );
    }
  };

  return (
    <>
      <div className="mt-4 mb-4 overflow-x-auto">
        <table className="border-collapse w-full border border-gray-300 bg-white text-sm shadow-sm whitespace-nowrap">
          <thead>
            <tr className="sticky top-0 border-t bg-gray-100 border-b-0">
              {columns.map((column) => (
                <th
                  key={column.id}
                  className={`w-auto border-0 hover:bg-blue-50 border-gray-300 border-r cursor-pointer 
                    font-semibold py-1 pr-[5px] pl-[5px] ${
                      column.headerAlign
                    } ${sortConfig.key === column.id ? "bg-blue-300" : ""}`}
                  onClick={() => handleSort(column.id)}
                >
                  {column.label}
                  {sortConfig.key === column.id &&
                    (sortConfig.direction === "asc" ? "▲" : "▼")}
                </th>
              ))}
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {sortedData.map((row, index) => (
                <tr
                  key={index}
                  tabIndex={-1}
                  className={`hover:bg-blue-50 border-t border-b-0 ${row.attributes["flagged_for_prepress_alert"] === 1 ? "bg-[#ffecf0] text-[#c82124]": ""}`}>
                  {columns.map((column) => {
                    const value = column.filterOptions
                      ? column?.filterOptions?.find(
                          (item) => item.value === row.attributes[column.id]
                        )?.label
                      : row.attributes[column.id];
                    return (
                      <td
                        key={column.id}
                        className={`w-auto border-0 border-gray-500 font-normal ${columnColors(
                          column,
                          value
                        )} py-1 ${column.align} pr-[5px] pl-[5px] 
                          ${
                            column.label === COLUMN_LABELS.PRODUCT_TYPE ||
                            column.label === COLUMN_LABELS.LOCATION
                              ? "max-w-[100px] truncate overflow-hidden cursor-pointer"
                              : ""
                          }`}
                      >
                        {renderCellValue(
                          column,
                          row,
                          orderDetailsApi,
                          openLinkInTab,
                          appliedFilters
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {loading && <div className="p-2 text-center">Loading...</div>}
        {!loading && !error && sortedData.length === 0 && (
          <div className="p-2 text-center">No Data Found</div>
        )}
      </div>
    </>
  );
};

export default Table;
