import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import tableData from "./reducers/tableData";
import { pcApi } from './components/Api/apiService';
import notificationsSlice from './components/Notifications/notificationsSlice';
import authSlice from './components/Auth/authSlice';
import user from "./components/Auth/loginSlice";

const middleware = getDefaultMiddleware({
  immutableCheck: false, // Disable ImmutableStateInvariantMiddleware
  serializableCheck: false, // Disable SerializableStateInvariantMiddleware
});

export const store = configureStore({
  reducer: {
    auth: authSlice,
    [pcApi.reducerPath]: pcApi.reducer,
    notifications: notificationsSlice,
    tableData: tableData,
    user: user
  },
  devTools: {
    trace: true,
  },
  middleware: middleware.concat(pcApi.middleware),
});
