import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedIdsForUser,
  updateSelectedIdsForUser,
} from "../../utils/storageUtils";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { useSaveProjectCoordinatorMutation } from "../Api/apiService";
import { notificationAdded } from "../Notifications/notificationsSlice";
import { selectUserFullName } from "../Auth/authSlice";

const Dropdown = ({
  className = "",
  label,
  placeholder = "All PCs",
  options = [],
  onChange,
  isClearFilter,
  showEdit,
  allPcs,
  onApiUpdate,
  includedList,
  isQueueLoading,
}) => {
  const user = useSelector(selectUserFullName);
  const [selectedIds, setSelectedIds] = useState(getSelectedIdsForUser(user));
  const [selectedPCIds, setSelectedPCIds] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [displayPC, setDisplayPC] = useState([]);
  const [excludedList, setExcludedList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditQueueOpen, setIsEditQueueOpen] = useState(false);
  const dispatch = useDispatch();

  const handleItemClick = (id) => {
    if (onChange) {
      const updatedSelectedIds = selectedIds.includes(id)
        ? selectedIds.filter((selectedId) => selectedId !== id)
        : [...selectedIds, id];
      setSelectedIds(updatedSelectedIds);
      onChange(updatedSelectedIds);

      updateSelectedIdsForUser(user, updatedSelectedIds);
    }
  };

  const handleRemoveSelectedPc = (id) => {
    const updatedSelectedIds = selectedIds.filter(
      (selectedId) => selectedId !== id
    );
    setSelectedIds(updatedSelectedIds);
    onChange(updatedSelectedIds);
    updateSelectedIdsForUser(user, updatedSelectedIds);
  };

  const handleClearAll = () => {
    setSelectedIds([]);
    onChange([]);
    updateSelectedIdsForUser(user, []);
  };

  useEffect(() => {
    if (isClearFilter) {
      setSelectedIds([]);
      onChange([]);
    }
  }, [isClearFilter, onChange]);

  const getFilteredOptions = () => {
    return options.filter(({ pcname }) => selectedIds.includes(pcname));
  };

  const openEditQueue = () => {
    setIsEditQueueOpen(true);
    setSearchTerm("")
  }

  const closeEditQueue = () => {
    setIsEditQueueOpen(false);
    setDisplayPC(includedList);
    const updatedExcludedList = allPcs.filter(
      (pc) => !options.some((option) => option?.pcid === pc?.pcid)
    );
    setExcludedList(updatedExcludedList);
  };

  const [saveProjectCoordinator] = useSaveProjectCoordinatorMutation();

  const savePCList = async () => {
    setIsLoading(true);
    const payload = JSON.stringify(displayPC);

    try {
      const response = await saveProjectCoordinator(payload);
      if (response?.data) {
        onApiUpdate();
        setIsEditQueueOpen(false);
      }
    } catch (error) {
      dispatch(
        notificationAdded({
          title: "Uh-oh!",
          message: "Could not save the PCs list.",
          type: "warning",
        })
      );
    }
    finally {
      setIsLoading(false);
    }
  };
  const sortedOptions = [...options].sort((a, b) => {
    const aName = a.pcname || a.pcname;
    const bName = b.pcname || b.pcname;

    return aName.localeCompare(bName);
  });

  useEffect(() => {
    if (allPcs && allPcs.length > 0) {
      const updatedExcludedList = allPcs.filter(
        (pc) => !options.some((option) => option?.pcid === pc?.pcid)
      );
      setExcludedList(updatedExcludedList);
    }
  }, [allPcs, options]);

  useEffect(() => {
    if (includedList && includedList.length > 0) {
      setDisplayPC(includedList);
    }
  }, [includedList]);

  const moveToIncluded = () => {
    const itemsToAdd = selectedPCIds.filter(
      (selected) => !displayPC.some((pc) => pc.pcid === selected.pcid)
    );

    if (itemsToAdd.length > 0) {
      setDisplayPC((prevDisplayPC) => [...prevDisplayPC, ...itemsToAdd]);
      const updatedExcludedPC = excludedList.filter(
        (excluded) =>
          !selectedPCIds.some((selected) => selected.pcid === excluded.pcid)
      );

      setExcludedList(updatedExcludedPC);
      setSelectedPCIds([]);
    }
  };

  const moveToExcluded = () => {
    if (selectedPCIds.length > 0) {
      const updatedDisplayPC = displayPC.filter(
        (pc) => !selectedPCIds.some((selected) => selected.pcid === pc.pcid)
      );

      const updatedExcludedPC = [
        ...excludedList,
        ...selectedPCIds.filter(
          (selected) =>
            !excludedList.some((item) => item.pcid === selected.pcid)
        ),
      ];

      setDisplayPC(updatedDisplayPC);
      setExcludedList(updatedExcludedPC);
    }

    setSelectedPCIds([]);
    setSelectedItem(null);
  };

  const handleToggleSelection = (selectedPC) => {
    setSelectedPCIds((prevSelected) => {
      if (prevSelected.some((item) => item.pcid === selectedPC.pcid)) {
        return prevSelected.filter((item) => item.pcid !== selectedPC.pcid);
      } else {
        return [...prevSelected, selectedPC];
      }
    });
  };

  const [searchTerm, setSearchTerm] = useState("");

  const filteredList = excludedList?.filter(({ pcname }) =>
    pcname?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const filteredPC = displayPC?.filter(({ pcname }) =>
    pcname?.toLowerCase().includes(searchTerm?.toLowerCase())
  );
  const handleSearch = (input) => {
    setSearchTerm(input);
  };

  const renderList = (list, selectedPCIds) => {
    if (list?.length > 0) {
      return list.map(({ pcid, pcname }) => (
        <div
          key={pcid}
          className={`flex items-center font-inter justify-between text-sm mb-1 cursor-pointer 
            ${
              selectedPCIds?.some((selected) => selected.pcid === pcid)
                ? "bg-blue-500 text-white"
                : "hover:bg-gray-200"
            }`}
          onClick={() => handleToggleSelection({ pcid, pcname })}
        >
          <span>{pcname}</span>
        </div>
      ));
    } else {
      return <div className="text-gray-500 text-sm">No results found</div>;
    }
  };

  return (
    <div className="relative inline-block text-left">
      {label && <div className="mb-1 text-sm">{label}</div>}
      <Menu as="div" className="inline-block">
        <div>
          <Menu.Button
            className="inline-flex justify-between rounded-md border border-gray-300 bg-white px-4 py-2 w-52 text-sm
            font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500
            focus:ring-offset-2 focus:ring-offset-gray-100"
          >
            {placeholder}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
          {showEdit && (
            <button
              className={`ml-2 inline-flex items-center justify-center rounded-md bg-indigo-500 text-white px-4 py-2 text-sm font-medium font-inter shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100 ${
                isQueueLoading
                  ? " bg-gray-400 cursor-wait"
                  : "bg-blue-500 cursor-pointer"
              }`}
              onClick={openEditQueue}
              disabled={isQueueLoading}
            >
              Edit Queue
            </button>
          )}
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1
          ring-black ring-opacity-5 focus:outline-none overflow-y-auto max-h-60"
          >
            <div className="py-1">
              {sortedOptions.map(({ pcid, pcname }) => {
                const isActive = selectedIds.includes(pcname);
                const handleClick = () => handleItemClick(pcname);

                return (
                  <Menu.Item key={pcid}>
                    {({ active }) => (
                      <div
                        className={`${
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                        } block px-4 py-2 text-sm`}
                        onClick={handleClick}
                      >
                        <input
                          type="checkbox"
                          checked={isActive}
                          readOnly
                          className="mr-2"
                        />
                        {pcname}
                      </div>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
        {selectedIds?.length > 0 && (
          <div className="flex flex-wrap mb-4 mt-2">
            {getFilteredOptions().map(({ pcid, pcname }) => (
              <div
                className="border-2 border-gray-600 mr-2 mb-2 p-2 rounded-lg flex justify-between"
                key={pcid}
              >
                {pcname}{" "}
                <span
                  className="text-gray-500 cursor-pointer ml-4"
                  onClick={() => handleRemoveSelectedPc(pcname)}
                >
                  <XCircleIcon className="h-4 w-4" aria-hidden="true" />
                </span>
              </div>
            ))}
            <button
              className="text-sm text-[#506986] cursor-pointer ml-2 underline hover:text-gray-700"
              onClick={handleClearAll}
            >
              Clear All
            </button>
          </div>
        )}
      </Menu>

      <Transition appear show={isEditQueueOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeEditQueue}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl h-[500px] transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <button
                    className="absolute top-3 right-3 rounded-md text-black-400 hover:text-black-600 focus:outline-none"
                    onClick={closeEditQueue}
                  >
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium font-inter leading-6 text-gray-900"
                  >
                    Edit Queue
                  </Dialog.Title>
                  <p className=" text-sm mt-4 leading-6 font-inter text-gray-700">
                    Select the Project Coordinators that will be shown on the
                    dashboard.
                  </p>
                  <div className="mt-2">
                    <div className="flex w-full mb-4">
                      <input
                        type="text"
                        placeholder="Search..."
                        onChange={(e) => handleSearch(e.target.value)}
                        value={searchTerm}
                        className="flex-grow block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:font-medium placeholder:text-gray-700 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-4 font-inter"
                      />
                    </div>
                    <div className="flex items-start space-x-4 mb-4">
                      {/* Excluded Section */}
                      <div className="flex-1">
                        <h4 className="font-semibold font-inter text-sm mb-2 text-left">
                          Excluded
                        </h4>
                        <div className="border rounded p-2 overflow-y-auto max-h-60 ">
                          {renderList(filteredList, selectedPCIds)}
                        </div>
                      </div>

                      {/* Move Buttons */}
                      <div className="flex flex-col justify-center items-center space-y-4 mt-20">
                        <FiChevronRight
                          className="  bg-white-300 border rounded  text-gray-400 "
                          onClick={moveToIncluded}
                          size={20}
                          disabled={
                            !selectedItem || selectedIds?.includes(selectedItem)
                          }
                        />

                        <FiChevronLeft
                          className=" bg-white-300 border rounded  text-gray-400"
                          onClick={moveToExcluded}
                          size={20}
                          disabled={
                            !selectedItem ||
                            !selectedIds?.includes(selectedItem)
                          }
                        />
                      </div>
                      {/* Included Section */}
                      <div className="flex-1">
                        <h4 className="font-semibold font-inter text-sm mb-2 text-left">
                          Included
                        </h4>
                        <div className="border rounded p-2 overflow-y-auto h-60">
                          {renderList(filteredPC, selectedPCIds)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-7 flex justify-end space-x-2">
                    <button
                      className="px-6 py-2 bg-gray-200 text-gray-700 font-inter rounded-md hover:bg-gray-300"
                      onClick={closeEditQueue}
                    >
                      Cancel
                    </button>
                    <button
                      className={`px-4 py-2 text-white font-inter rounded-md ${
                        isLoading
                          ? "bg-gray-400 cursor-wait"
                          : "bg-blue-500 hover:bg-blue-600"
                      }`}
                      onClick={savePCList}
                      disabled={isLoading}
                    >
                      {isLoading ? "Saving..." : "Save"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Dropdown;
