import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import {store} from './store';

import App from './App';
import Login from '../src/components/Auth/Login';
import ProtectedRoute from '../src/components/Auth/ProtectedRoute';
import Header from './components/Header';
import Notifications from './components/Notifications/Notifications';

const root = document.getElementById('root');

createRoot(root).render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<ProtectedRoute />}>
            <Route index element={<>
              <Header />
              <App />
            </>} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
      <Notifications/>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
