import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotifications, notificationRemoved } from './notificationsSlice';
import { Transition } from '@headlessui/react';
import { HiOutlineCheckCircle, HiX, HiOutlineExclamation, HiOutlineInformationCircle } from "react-icons/hi";
import { NOTIFICATION_TIMEOUT, TAILWIND_NOTIFICATION_DURATION } from './notificationTimeout';

export default function Notifications () {
    const notifications = useSelector(selectNotifications);

    return (
        <div className="fixed top-3 right-3 flex flex-col gap-2 font-sans" aria-live="assertive">
            {notifications.map(notification => <Notification key={notification.id} {...notification} />)}
        </div>
    );
}

function Notification ({ title, message, type, id, active }) {
    const [isShowing, setIsShowing] = useState(true);
    const dispatch = useDispatch();
    const el = useRef(null);

    const isSuccess = type === 'success';
    const isWarning = type === 'warning';
    const isInfo = type === 'info';

    const shouldDismiss = isSuccess;

    useEffect(() => {
        if (shouldDismiss) {
            setTimeout(() => setIsShowing(false), NOTIFICATION_TIMEOUT);
        }
    }, [shouldDismiss]);

    useEffect(() => {
        if (!active) {
            setIsShowing(false);
        }
    }, [active])

    useLayoutEffect(() => {
        if (!isShowing && el.current) {
            el.current.style.marginTop = `-${el.current.offsetHeight}px`;
        }
    }, [isShowing]);

    return (
        <Transition
            ref={el}
            show={isShowing}
            appear={true}
            afterLeave={() => dispatch(notificationRemoved({ id }))}
            enter="transition transform ease-in-out duration-300"
            enterFrom="opacity-0 translate-x-20"
            enterTo="opacity-100 translate-x-0"
            leave="transition-notification ease-in-out duration-300"
            leaveFrom="m-t-0 opacity-100"
            leaveTo="opacity-0"
        >
            <div className={`w-auto sm:w-96 bg-white ${isWarning ? 'bg-red-200' : isInfo ? 'bg-white' : ''} shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden`}>
                <div className="p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0">
                            {isSuccess && <HiOutlineCheckCircle className="h-6 w-6 text-green-400" aria-hidden="true" />}
                            {isWarning && <HiOutlineExclamation className="h-6 w-6 text-red-700" aria-hidden="true" />}
                            {isInfo && <HiOutlineInformationCircle className="h-6 w-6 text-blue-700" aria-hidden="true" />}
                        </div>
                        <div className="ml-3 flex-1 pt-0.5">
                            <p className="text-sm font-medium text-gray-900">{title}</p>
                            <p className="mt-1 text-sm text-gray-500">{message}</p>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex">
                            <button
                                type="button"
                                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                onClick={() => {
                                    setIsShowing(false)
                                }}
                            >
                                <span className="sr-only">Close</span>
                                <HiX className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                    {shouldDismiss && (
                        <Transition
                            show={true}
                            appear={true}
                            enter={`ease-linear transition-[width] ${TAILWIND_NOTIFICATION_DURATION}`}
                            enterFrom="w-0"
                            enterTo="w-full"
                            leave="w-full"
                        >
                            <div className="h-0.5 bg-green-600 mt-2"></div>
                        </Transition>
                    )}
                </div>
            </div>
        </Transition>
    )
}
