import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import API_URL from "./API_URL.constant";
import { setCredentials, clearCredentials } from "../Auth/authSlice";
import { notificationAdded } from "../Notifications/notificationsSlice";
import { loginSuccess } from "../Auth/loginSlice";
import { projectCoordinatorApi } from "../../constants/actionTypes"; 

// Base query for authenticated endpoints
const authBaseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    headers.set("Content-Type", "application/json");
    return headers;
  },
});

// Base query for unauthenticated endpoints
const noAuthBaseQuery = fetchBaseQuery({
  baseUrl: `${projectCoordinatorApi}`,
  // prepareHeaders: (headers) => {
  //     headers.set('Content-Type', 'application/json');
  //     return headers;
  // },
});

// Reusable base query with re-authentication handling
const baseQueryWithReauth = async (args, api, extraOptions, baseQueryFn) => {
  const result = await baseQueryFn(args, api, extraOptions);

  if (result.error) {
    switch (result.error.status) {
      case 401: // Unauthorized, handle token refresh
        const { user } = api.getState().auth;
        if (!user) break;

        const refreshResult = await api.dispatch(
          pcApi.endpoints.refresh.initiate()
        );
        if (!refreshResult || refreshResult.error) {
          api.dispatch(clearCredentials());
          break;
        }
        return await baseQueryFn(args, api, extraOptions);

      case 403:
        break;

      case 404:
        break;

      default:
        if (result.error.data?.errors) {
          result?.error?.data?.errors.forEach((error) => {
            api.dispatch(
              notificationAdded({
                title: "Uh-oh!",
                message: error?.detail,
                type: "warning",
              })
            );
          });
        } else {
          api.dispatch(
            notificationAdded({
              title: "Uh-oh!",
              message: result?.error?.data?.message,
              type: "warning",
            })
          );
        }
    }
  }

  return result;
};


export const pcApi = createApi({
  reducerPath: "pcApi",
  tagTypes: ["Template", "Site", "ProjectCoordinator"],
  baseQuery: async (args, api, extraOptions) => {
    const selectedBaseQuery = authBaseQuery;
    return baseQueryWithReauth(args, api, extraOptions, selectedBaseQuery);
  },
  endpoints: (build) => ({
    login: build.mutation({
      queryFn: async (arg, api, extraOptions, baseQueryFn) => {
        try {
          const { username, password } = arg;
          const response = await fetch(`${API_URL}/login`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username, password, supplierId: 1 }),
          }).then((res) =>
            res.ok
              ? res.json()
              : { error: { data: { message: "Invalid credentials." } } }
          );

          if (response.error) {
            return response;
          }
          const user = { name: response.fullName };
          api.dispatch(setCredentials({ token: response.apiToken }));
          api.dispatch(loginSuccess(user));

          return { data: null };
        } catch (err) {
          return { error: err };
        }
      },
    }),
    refresh: build.mutation({
      queryFn: async (arg, api, extraOptions, baseQueryFn) => {
        try {
          const response = await fetch(`${API_URL}/refreshToken`, {
            headers: { "Content-Type": "application/json" },
          }).then((res) =>
            res.ok
              ? res.json()
              : { error: { data: { message: "Token refresh failed." } } }
          );

          if (response.error) {
            return response;
          }
          api.dispatch(setCredentials({ token: response.apiToken }));
          return { data: null };
        } catch (err) {
          return { error: err };
        }
      },
    }),
    tokenFromSession: build.mutation({
      queryFn: async (arg, api, extraOptions, baseQueryFn) => {
        try {
          const response = await fetch(
            `${API_URL}/rest/v1/supplierAdmin/adminUsers/jwtFromSession`,
            {
              headers: { "Content-Type": "application/json" },
            }
          ).then((res) =>
            res.ok
              ? res.json()
              : {
                  error: {
                    data: { message: "Session token retrieval failed." },
                  },
                }
          );

          if (response.error) return response;

          api.dispatch(setCredentials({ token: response.apiToken }));
          return { data: null };
        } catch (err) {
          return { error: err };
        }
      },
    }),
    saveProjectCoordinator: build.mutation({
      query: (data) => ({
        url: `/pcusers`,
        method: "POST",
        body: data,
      }),
      baseQuery: noAuthBaseQuery,
    }),
    getQueueInfo: build.query({
      query: () => ({
        url: `/pcs`,
        method: "GET",
      }),
      baseQuery: noAuthBaseQuery,
    }),
    getActiveUsers: build.query({
      query: () => ({
        url: `/pcactiveusers`,
        method: "GET",
      }),
      baseQuery: noAuthBaseQuery,
    }),
    getAdminAccess: build.query({
      query: () => ({
        url: `/pcqueueadministrator`,
        method: "GET",
      }),
      baseQuery: noAuthBaseQuery,
    }),
  }),
});

export const {
  useLoginMutation,
  useRefreshMutation,
  useTokenFromSessionMutation,
  useSaveProjectCoordinatorMutation,
  useGetQueueInfoQuery,
  useGetActiveUsersQuery,
  useGetAdminAccessQuery,
} = pcApi;
