import "./App.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "./components/Dropdown";
import Table from "./components/Table";
import Pagination from "./components/Pagination";
import ReconnectingWebSocket from "reconnecting-websocket";
import { addAndUpdateTableData, PAGE_LIMIT_OPTIONS, webSocketApi } from "./constants/actionTypes";
import SearchInput from "./components/Filters";
import { useGetActiveUsersQuery, useGetQueueInfoQuery,useGetAdminAccessQuery } from "./components/Api/apiService";
import Widget from "../src/components/Widget/index";
import { getSelectedIdsForUser } from "./utils/storageUtils";
import StandbyAlert from "./components/StandByAlert";
import { selectUserFullName } from "./components/Auth/authSlice";

const columns = [
  { id: "order_number", label: "Order #", minWidth: 211, align: "text-right text-blue-800 underline", headerAlign: "text-right" },
  { id: "id", label: "Job #", minWidth: 211, align: "text-right text-blue-800 underline", headerAlign: "text-right" },
  { id: "pcq_status", label: "Approved", minWidth: 211, align: "text-center", headerAlign: "text-center" },
  { id: "adjustment_needed", label: "Price Flag", minWidth: 211, align: "text-center", headerAlign: "text-center" },
  { id: "order_created_on_date", label: "Ordered On", minWidth: 73, align: "text-right", headerAlign: "text-right" },
  { id: "total_price", label: "Price", minWidth: 73, align: "text-right", headerAlign: "text-right" },
  { id: "order_status", label: "Order Status", minWidth: 200, align: "text-left", headerAlign: "text-left" },
  { id: "turn_time", label: "Turn Time", minWidth: 120, align: "text-left", headerAlign: "text-left" },
  { id: "product_type", label: "Product Type", minWidth: 120, align: "text-left", headerAlign: "text-left" },
  { id: "proof_status", label: "Proof Status", minWidth: 120, align: "text-left", headerAlign: "text-left" },
  { id: "last_log_update", label: "Last Log Update", minWidth: 80, align: "text-right", headerAlign: "text-right" },
  { id: "ship_date", label: "Ship Date", minWidth: 120, align: "text-right", headerAlign: "text-right" },
  { id: "pc", label: "PC", minWidth: 120, align: "text-left", headerAlign: "text-left" },
  { id: "location_name", label: "Location", minWidth: 120, align: "text-left", headerAlign: "text-left" }
];

const App = () => {
  const user = useSelector(selectUserFullName);
  const dispatch = useDispatch();
  const selectedIds = getSelectedIdsForUser(user);
  const initialRowsData = useSelector((state) => state.tableData.data.filter((row) => selectedIds.includes(row.attributes.pc))) || [];
  const tableRowsData = useSelector((state) => state.tableData.data) || [];
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPcs, setSelectedPcs] = useState([]);
  const [pageSize] = useState(PAGE_LIMIT_OPTIONS[0].id);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedData, setSortedData] = useState([]);
  const [jobNumberSearchValue, setJobNumberSearchValue] = useState("");
  const [orderNumberSearchValue, setOrderNumberSearchValue] = useState("");
  const [num, setNum] = useState(0);
  const [activeTabId, setActiveTabId] = useState(1);
  const [isClearFilter, setIsClearFilter] = useState(false);
  const [quickFilterColumn, setQuickFilterColumn] = useState(null);
  const [quickFilterKey, setQuickFilterKey] = useState(null);

  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

  const { data: apiData, refetch } = useGetActiveUsersQuery();
  const { data: adminAccess, isSuccess } = useGetAdminAccessQuery();
  const showEditQueue = isSuccess && adminAccess.some((data) => data?.PC === user);
  const { data: allPcs, isLoading } = useGetQueueInfoQuery(null, {
    skip: !showEditQueue,
  });

  const signedUser = { pcid: user, pcname: user };
  const pcData = apiData?.some((value) => signedUser.pcname === value.pcname)
    ? apiData
    : [...(apiData || []), signedUser];

  const formattingJobIdData = (data) => {
    return data?.map((item) => {
      if (item.attributes && typeof item.attributes.id !== "string") {
        item.attributes.id = String(item.attributes.id)
          .split("")
          .reverse()
          .join("")
          .replace(/00000000/, "-")
          .split("")
          .reverse()
          .join("");
      }
      return item;
    });
  };

  useEffect(() => {
    const socket = new ReconnectingWebSocket(webSocketApi);
    socket.onopen = () => {
      console.log("WebSocket connection established.");
      const data = {
        action: "default",
      };
      try {
        socket.send(JSON.stringify(data));
      } catch (error) {
        console.error("WebSocket send error:", error);
      }
    };

    socket.onmessage = (event) => {
      let data = JSON.parse(event.data);
      const updatedData = formattingJobIdData(data);
      dispatch(addAndUpdateTableData(updatedData));
      setLoading(false);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      setLoading(true);
      setError("Failed to receive data from WebSocket");
    };

    socket.onclose = (event) => {
      console.log("WebSocket connection closed:", event);
      setLoading(false);
      const disconnect = {
        action: "disconnect",
      };
      try {
        socket.send(JSON.stringify(disconnect));
      } catch (error) {
        console.error("WebSocket send error:", error);
      }
    };

    return () => {
      socket.close();
    };
  }, [dispatch]);


  const handleChange = (selectedValues) => {
    setNum(1);
    const updatedPcData = pcData?.filter((value) =>
      selectedValues.includes(value.pcname)
    );
    setSelectedPcs(updatedPcData);
    const filteredData = tableRowsData?.filter((row) =>
      updatedPcData.some((value) => value.pcname === row.attributes.pc)
    );
    setSortedData(filteredData);
    setCurrentPage(1);
    setIsClearFilter(false);
  };

  const handleSearchChange = (event) => {
    setJobNumberSearchValue(event.target.value);
    setCurrentPage(1);
  };

  const handleOrderNumberSearchChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, "");
    setOrderNumberSearchValue(numericValue);
    setCurrentPage(1);
  };

  const handleClearSearch = () => {
    setJobNumberSearchValue("");
    setOrderNumberSearchValue("");
  };

  let dataToFilter;
  if (num === 0 && initialRowsData.length !== 0) {
    dataToFilter = selectedPcs.length > 0 ? sortedData : initialRowsData;
  } else if (num === 0 && initialRowsData.length === 0) {
    dataToFilter = [];
  } else {
    dataToFilter = selectedPcs.length > 0 ? sortedData : tableRowsData;
  }

// Define constants for column names and values
const COLUMN_NAMES = {
  PROOF_STATUS: "proof_status",
  ADJUSTMENT_NEEDED: "adjustment_needed",
  PRODUCTION_STATUS_NAME: "production_status_name",
  PCQ_STATUS: "pcq_status",
  ID: "id",
  ORDER_NUMBER: "order_number",
};

const COLUMN_VALUES = {
  NOT_SET: "Not Set",
  PCQ_STATUS_ACTIVE: "1",
};

const getIndividualCardCount = (columnName, keys) => {
  const filteredData =
    columnName && keys && keys?.length
      ? dataToFilter.filter((row) => {
          if (columnName === COLUMN_NAMES.PROOF_STATUS) {
            return (
              keys.includes(row.attributes[columnName]) &&
              row.attributes[COLUMN_NAMES.PRODUCTION_STATUS_NAME] ===
                COLUMN_VALUES.NOT_SET &&
              row.attributes[COLUMN_NAMES.PCQ_STATUS] ===
                COLUMN_VALUES.PCQ_STATUS_ACTIVE
            );
          } else if (columnName === COLUMN_NAMES.ADJUSTMENT_NEEDED) {
            return (
              keys.includes(row.attributes[columnName]) &&
              row.attributes[COLUMN_NAMES.PCQ_STATUS] ===
                COLUMN_VALUES.PCQ_STATUS_ACTIVE
            );
          } else {
            return keys.includes(row.attributes[columnName]);
          }
        })
      : dataToFilter;

  return filteredData?.length || 0;
};

const handleQuickFilter = (id, columnName, keys) => {
  setQuickFilterColumn(columnName);
  setQuickFilterKey(keys);

  setActiveTabId(id);
  handleClearSearch();
};

const filteredTableRowsData = dataToFilter.filter(
  (row) =>
    String(row.attributes[COLUMN_NAMES.ID]).includes(jobNumberSearchValue) &&
    String(row.attributes[COLUMN_NAMES.ORDER_NUMBER]).includes(
      orderNumberSearchValue
    )
);

const filteredTableRowsWithQuickFilter =
  quickFilterColumn && quickFilterKey
    ? filteredTableRowsData.filter((row) => {
        if (quickFilterColumn === COLUMN_NAMES.PROOF_STATUS) {
          return quickFilterKey.some(
            (key) =>
              row.attributes[quickFilterColumn]?.includes(key) &&
              row.attributes[COLUMN_NAMES.PRODUCTION_STATUS_NAME] ===
                COLUMN_VALUES.NOT_SET &&
              row.attributes[COLUMN_NAMES.PCQ_STATUS] ===
                COLUMN_VALUES.PCQ_STATUS_ACTIVE
          );
        } else if (quickFilterColumn === COLUMN_NAMES.ADJUSTMENT_NEEDED) {
          return quickFilterKey.some(
            (key) =>
              row.attributes[quickFilterColumn].includes(key) &&
              row.attributes[COLUMN_NAMES.PCQ_STATUS] ===
                COLUMN_VALUES.PCQ_STATUS_ACTIVE
          );
        } else {
          return quickFilterKey.some((key) =>
            String(row.attributes[quickFilterColumn]).includes(key)
          );
        }
      })
    : filteredTableRowsData;

  return (
    <div className="p-8">
      <h1>Project Coordinator Queue</h1>
      <Dropdown
        className="mr-5"
        label="Project Coordinator"
        options={pcData}
        onChange={handleChange}
        showEdit={showEditQueue}
        isClearFilter={isClearFilter}
        allPcs={allPcs}
        isQueueLoading={isLoading}
        includedList={apiData}
        onApiUpdate={refetch}
      />
      <StandbyAlert />
      <Widget
        activeTabId={activeTabId}
        options={[
          {
            id: 1,
            label: "All Active Jobs",
            count: getIndividualCardCount(),
            onClick: (id) => handleQuickFilter(id),
          },
          {
            id: 2,
            label: "Production status not set",
            count: getIndividualCardCount("production_status_name", [
              "Not Set",
            ]),
            onClick: (id) =>
              handleQuickFilter(id, "production_status_name", ["Not Set"]),
          },
          {
            id: 3,
            label: "All Approved",
            count: getIndividualCardCount("proof_status", ["Approved"]),
            onClick: (id) =>
              handleQuickFilter(id, "proof_status", ["Approved"]),
          },
          {
            id: 4,
            label: "In Production",
            count: getIndividualCardCount("production_status_name", [
              "Production Ready",
              "Queued For Print",
              "Printed",
              "Finished",
            ]),
            onClick: (id) =>
              handleQuickFilter(id, "production_status_name", [
                "Production Ready",
                "Queued For Print",
                "Printed",
                "Finished",
              ]),
          },
          {
            id: 5,
            label: "Price Adjustments",
            count: getIndividualCardCount("adjustment_needed", ["true"]),
            onClick: (id) =>
              handleQuickFilter(id, "adjustment_needed", ["true"]),
          },
          {
            id: 6,
            label: "Split Shipping",
            count: getIndividualCardCount("split_shipment_alert", [1]),
            onClick: (id) => handleQuickFilter(id, "split_shipment_alert", [1]),
          },
        ]}
      />
      <div className="flex justify-between">
        <div className="flex items-center">
          <SearchInput
            value={orderNumberSearchValue}
            onChange={handleOrderNumberSearchChange}
            placeholder="Order #"
          />
          <SearchInput
            value={jobNumberSearchValue}
            onChange={handleSearchChange}
            placeholder="Job #"
          />
          <button
            className="text-sm text-[#506986] cursor-pointer ml-2 mt-7 underline hover:text-gray-700 focus:outline-none "
            onClick={handleClearSearch}
          >
            Clear
          </button>
        </div>
        <div className="flex items-center">
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            totalRows={filteredTableRowsWithQuickFilter.length}
            totalPages={Math.ceil(
              filteredTableRowsWithQuickFilter.length / pageSize
            )}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <Table
        className="mb-2"
        currentPage={currentPage}
        pageSize={pageSize}
        rowsData={filteredTableRowsWithQuickFilter}
        columns={columns}
        loading={loading}
        error={error}
      />
      <div className="flex justify-end">
        <Pagination
          currentPage={currentPage}
          pageSize={pageSize}
          totalRows={filteredTableRowsWithQuickFilter.length}
          totalPages={Math.ceil(
            filteredTableRowsWithQuickFilter.length / pageSize
          )}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default App;
